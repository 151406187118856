@import "../../style/fonts.scss";
.wrapper-recent-works-t3d {
    width: 100%;
    transform-origin: 50% 100%;
    // transform: translateY(0);
    // opacity: 1;
    // animation-name: showpageRecentWorks;
    // animation-duration: 1s;
    // background-color: white;

    // &.hidden {
    //     animation-name: hiddenpage;
    //     animation-duration: 0.5s;
    //     opacity: 1;
    // }
}

.container-content-recent-works {
    padding: 92px 20px 20px;
    box-sizing: border-box;
    width: 100%;

}


.header-content-recent-works {
    display: flex;
    width: 100%;
    justify-content:  center;
    align-items: flex-end;
    text-align: center;
    padding-bottom: 32px;
}

.title-header-recent-works {
    font-family: $font-family-1;
    font-style: normal;
    font-weight: 400;
    font-size: 124px;
    line-height: 150px;
    color: #000000;
    span {
        font-family: $font-family-4;
        font-weight: 400;
    }
}


.info-header-recent-works {
    font-family: $font-family-1;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    margin-bottom: 17px;

}

.filter-container-content {
    display: flex;
    border-bottom: 1px solid #DBDBDB;
    justify-content: center;
    padding-bottom: 14px;
}

.filter-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.item-filter {
    text-decoration: none;
    color: #000000;
    padding: 12px 12px;
    border: 1px solid transparent;
    font-family: $font-family-1;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin: 2px;
    cursor: pointer;
    border-radius: 12px;

    &:hover {
        background: #EDEDEE;
        border: 1px solid #EDEDEE;

    }
    &.active {
        border: 1px solid #DBDBDB;
    }
}

.grid-wrapper-projects {
    display: grid;
    grid-gap: 20px;
    box-sizing: border-box;
    padding: 0 20px 20px;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    color: #444;
}

.wrapper-item-grid {
    overflow: hidden;
    border-radius: 24px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
   

    font-family: $font-family-1;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    &.box-1 {
        grid-column: 1;
        grid-column: 1 / span 2;
        grid-row: 1 / span 2;
        min-height: calc( calc(72vw - 40px) / 3 );
    }
    &.box-2 {
        grid-column: 3;
        grid-row: 1;
        min-height: calc( calc(72vw - 40px) / 6 );
    }
    &.box-3 {
        grid-column: 3;
        grid-row: 2;
        min-height: calc( calc(72vw - 40px) / 6 );
    }
    &.box-4 {
        grid-column: 4;
        grid-column: 4 / span 2;
        grid-row: 1 / span 2;
        min-height: calc( calc(72vw - 40px) / 3 );
    }
    &.box-5 {
        grid-column: 6;
        grid-row: 1 / span 2;
        min-height: calc( calc(72vw - 40px) / 3 );
    }
    &.box-6 {
        grid-column: 1;
        grid-row: 3 / span 2;
        min-height: calc( calc(72vw - 40px) / 3 );
    }
    &.box-7 {
        grid-column: 2;
        grid-column: 2 / span 2;
        grid-row: 3 / span 2;
        min-height: calc( calc(72vw - 40px) / 3 );
    }
    &.box-8 {
        grid-column: 4;
        grid-row: 3 ;
        min-height: calc( calc(72vw - 40px) / 6 );
    }
    &.box-9 {
        grid-column: 4;
        grid-row: 4;
        min-height: calc( calc(72vw - 40px) / 6 );
    }
    &.box-10 {
        grid-column: 5;
        grid-column: 5 / span 2;
        grid-row: 3 / span 2;
        min-height: calc( calc(72vw - 40px) / 3 );
    }
    position: relative;
    transition: .6s;
    z-index: 4;
    &:hover {
        cursor: pointer;
        user-select: none;
        .item-grid {
            scale: 1.05;
        }
        .hover-effect-item-grid {
            opacity: 1;
        }
    }
}

.item-grid {
    z-index: 3;
    width: 100%;
    height: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: .6s;
    
}

.hover-effect-item-grid {
    z-index: 3;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 20px);

    display: flex;
    justify-content: center;
    align-items: center;
    // transform: translateY(60px);
    color: #FFFFFF;
    background: rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(12px);
    border-radius: 32px;
    height: 44px;
    transition: .6s;
    opacity: 0;
    svg {
        padding-left: 5px;
    }
}




.wrapper-btn-all-projects {
    width: 100%;
    max-width: 690px;
    // padding: 0 20px 120px 20px;
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
    margin: 0 auto;
}

.btn-all-projects {
    text-decoration: none;
    box-sizing: border-box;
    border-radius: 12px;
    border: 1px solid #DBDBDB;
    height: 106px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 25px;
    cursor: pointer;
    user-select: none;
    transition: .6s;



    &:hover {
        border: 1px solid #777777;
        .arrow-btn-all-projects {
                rect {
                    transition: .6s;
                    fill: black;
                }
                path {
                    transition: .6s;
                    fill: white;
                }
        }
    }
}

.left-btn-all-projects {
    width: 100%;
    display: flex;
    align-items: center;
    font-family: $font-family-1;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #777777;
    span {
        font-size: 16px;
        line-height: 19px
    }
}

.portfolio-lottie-btn {
    margin-right: 20px;
    width: 15%;

}

.qty-btn-all-projects {
    border-radius: 32px;
    background: #EEEDED;
    border: 1px solid #DBDBDB;
    margin-left: 16px;
    padding: 6px 16px;
    font-family: $font-family-1;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #777777;


}


@media screen  and (max-width: 1180px){ 
    
    .container-content-recent-works {
        padding: 74px 20px 20px;
    }

    .filter-content { 
        max-width: 500px;
    }
    .grid-wrapper-projects {
        grid-template-columns: 1fr 1fr 1fr;
    }
    
    .wrapper-item-grid {
        &.box-1 {
            grid-column: 1;
            grid-row: 1;
            min-height: calc( calc(72vw - 40px) / 3 );
        }
        &.box-2 {
            grid-column: 2;
            grid-row: 1;
            min-height: calc( calc(72vw - 40px) / 3 );
        }
        &.box-3 {
            grid-column: 1;
            grid-row: 2;
            min-height: calc( calc(72vw - 40px) / 3 );
        }
        &.box-4 {
            grid-column: 2;
            grid-row: 2;
            min-height: calc( calc(72vw - 40px) / 3 );
        }
        &.box-5 {
            grid-column: 3;
            grid-row: 1 / span 2;
            min-height: calc( calc(72vw - 40px) / 1.5 );
        }
        &.box-6 {
            grid-column: 1;
            grid-row: 3 / span 2;
            min-height: calc( calc(72vw - 40px) / 1.5 );
        }
        &.box-7 {
            grid-column: 2;
            grid-row: 3;
            min-height: calc( calc(72vw - 40px) / 3 );
        }
        &.box-8 {
            grid-column: 3;
            grid-row: 3;
            min-height: calc( calc(72vw - 40px) / 3 );
        }
        &.box-9 {
            grid-column: 2;
            grid-row: 4;
            min-height: calc( calc(72vw - 40px) / 3 );
        }
        &.box-10 {
            grid-column: 3;
            grid-row: 4;
            min-height: calc( calc(72vw - 40px) / 3 );
           
        }
    }

}


@media  (max-width: 767px), (max-height: 640px) { 

    .portfolio-lottie-btn {
        margin-right: 5px;
        width: 10%;
        min-width: 55px;
    
    }

    .container-content-recent-works {
        padding: 54px 6px 6px;
    }
    .header-content-recent-works {
        padding-bottom: 24px;
    }
    .title-header-recent-works {
        font-size: 46px;
        line-height: 56px;
    }

    .grid-wrapper-projects {
        grid-gap: 6px;
        padding: 0 6px 6px 6px;
    }



    .filter-content {
        display: none;
    }
    .filter-container-content  {
        padding: 0;
    }

    .wrapper-btn-all-projects {
        width: 100%;
        // padding: 0 6px 92px 6px;
        padding: 0 6px 6px 6px;
        box-sizing: border-box;
    }
    .btn-all-projects {
        height: 66px;
        padding: 0 12px;
    } 

    .right-btn-all-projects {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            height: 30px;
            width: 30px;
        }
    }

    .qty-btn-all-projects {
        display: none;
    }


    .left-btn-all-projects {
        span {
            font-size: 14px;
            line-height: 17px;
            
        }
        
    }
}

    


@media screen and (max-width: 767px) {

    .grid-wrapper-projects {
        grid-gap: 6px;
        grid-template-columns: 1fr 1fr;
        padding: 0 6px 6px 6px;
    }
    .wrapper-item-grid {
        border-radius: 12px;
        &.box-1 {
            grid-column: 1;
            grid-row: 1 / span 2;
            min-height: calc( calc(72vw - 12px));
        }
        &.box-2 {
            grid-column: 2;
            grid-row: 1;
            min-height: calc( calc(72vw - 12px) / 2 );
        }
        &.box-3 {
            grid-column: 2;
            grid-row: 2;
            min-height: calc( calc(72vw - 12px) / 2 );
        }
        &.box-4 {
            grid-column: 1;
            grid-row: 3;
            min-height: calc( calc(72vw - 12px) / 2 );
        }

        &.box-5 {
            grid-column: 2;
            grid-row: 3;
            min-height: calc( calc(72vw - 12px) / 2 );
        }

        &.box-6 {
            grid-column: 1;
            grid-row: 4;
            min-height: calc( calc(72vw - 12px) / 2 );
        }
        &.box-7 {
            grid-column: 2;
            grid-row: 4 / span 2;
            min-height: calc( calc(72vw - 12px));
        }
        &.box-8 {
            grid-column: 1;
            grid-row: 5;
            min-height: calc( calc(72vw - 12px) / 2 );
       
        }
        &.box-9 {
            grid-column: 1;
            grid-row: 6;
            min-height: calc( calc(72vw - 12px) / 2 );
 
        }
        &.box-10 {
            grid-column: 2;
            grid-row: 6;
            min-height: calc( calc(72vw - 12px) / 2 );
           
        }
    }

}



@media screen and (min-width: 1920px) {

    .wrapper-item-grid { 

        font-size: 21px;
        line-height: 20px;

    }

}
