:root {
  --app-height: 100%;
}
body {
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.wrapper-loader {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  background-color: white;
  z-index: 10000002;
  justify-content: center;
  align-items: center;
  &.hidden {
    animation-name: hiddenloader;
    animation-duration: 1s;
    transform: translateY(-100%);
  }
}
.wrapper-app-t3d {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
}
.wrapper-main {
  width: 100%;
  height: 100%;
  position: relative;

  transform: translateY(0);
  opacity: 1;
  animation-name: showpageMain;
  animation-duration: 1s;
  transform-origin: 50% 100%;
  transform: translateY(0);
  &.hidden {
    animation-name: hiddenpage;
    animation-duration: 0.5s;
    transform: scale(0.97);
  }
}

.blackout {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: black;
  opacity: 0;
  z-index: 10;
  pointer-events: none;
  transition: 0.5s;
  &.active {
    opacity: 0.5;
  }
}

@keyframes showpageMain {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes showpageHome {
  // 0%   {transform: translateY(100%); opacity: 0;}
  // 100% {transform: translateY(0); opacity: 1;}
}
@keyframes showpageRecentWorks {
  // 0%   {transform: translateY(40%); opacity: 0;}
  // 100% {transform: translateY(0); opacity: 1;}
}
@keyframes showpageServices {
  // 0%   {transform: translateY(40%); opacity: 0;}
  // 100% {transform: translateY(0); opacity: 1;}
}
@keyframes showpageContact {
  // 0%   {transform: translateY(40%); opacity: 0;}
  // 100% {transform: translateY(0); opacity: 1;}
}

@keyframes showpageOurPortfolio {
  0% {
    transform: translateY(80%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes hiddenpage {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.97);
    opacity: 1;
    display: none;
  }
}

@keyframes hiddenloader {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
