@import "../../style/fonts.scss";

.wrapper-navigator-t3d {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 36px;
    z-index: 1002;

    font-family: $font-family-2;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}

.container-navigator {
    display: flex;
    background: rgba(0, 0, 0, 0.46);
    backdrop-filter: blur(30px);
    border-radius: 15px;
    padding: 0 15px;
    overflow: hidden;
}

.line-active-navigator {
    height: 3px;
    width: calc(100% - 30px);
    position: absolute;
    left: 15px;
    bottom: 0px;
    border-radius: 5px;
        .line  {
        background-color: #A2ADCE;
        border-radius: 5px 5px 0 0;
        height: 100%;
        position: absolute;
        width: 25%;
        left: 0;
        bottom: 0;
        transition: .5s;
        transform: translateX(0);
        &.recent-works {
            transform: translateX(100%);
        }
        &.our-portfolio {
            transform: translateX(420%);
        }
        &.services {
            transform: translateX(200%);
        }
        &.contact {
            transform: translateX(300%);
        }

    }


}

.item-navigator {
    text-decoration: none;
    color: white;
    margin: 15px 10px;
    width: 55px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    user-select: none;
            opacity: .65;
            a {
                margin: 0;
                padding: 0;
            }
            &.active {
                opacity: 1;
            }
            &.home-nav {
                ~ .line-active-navigator {
                    .line  { 
                        transform: translateX(0);
                   }
               }
           }
            &.recent-works-nav {
                 ~ .line-active-navigator {
                    .line  { 
                        transform: translateX(100%);
                    }
                }
            }
            &.services-nav {
                ~ .line-active-navigator {
                    .line  { 
                        transform: translateX(200%);
                   }
               }
           }
            &.contact-nav {
                 ~ .line-active-navigator {
                    .line  { 
                        transform: translateX(300%);
                    }
                }
            }

            &.our-portfolio-nav {
                ~ .line-active-navigator {
                   .line  { 
                       transform: translateX(500%);
                   }
               }
           }



            &:hover{
                opacity: 1;
            }     
}


@media (max-width: 767px),  (max-height: 640px) {
    .wrapper-navigator-t3d {
        bottom: 20px;
    }
    .item-navigator {
        margin: 12px 8px;
    }
  }
