@import "../../style/fonts.scss";
.wrapper-services-t3d {
    width: 100%;
    color: black;
    // transform-origin: 50% 100%;
    // background-color: white;
    // transform: translateY(0);
    // opacity: 1;
    // animation-name: showpageServices;
    // animation-duration: 1s;
    // &.hidden {
    //     animation-name: hiddenpage;
    //     animation-duration: 0.5s;
    //     opacity: 1;
    // }
}



.container-content-services {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}



.header-content-services {
    display: flex;
    width: 100%;
    justify-content:  center;
    align-items: flex-end;
    text-align: center;
    padding: 92px 0 32px;
    width: calc(100% - 40px);
    margin: 0 20px;
    max-width: 1675px;
    box-sizing: border-box;
    border-bottom: 1px solid #DBDBDB;
}
.title-header-services {
    font-family: $font-family-1;
    font-style: normal;
    font-weight: 400;
    font-size: 124px;
    line-height: 150px;
    color: #000000;
    span {
        font-family: $font-family-4;
        font-weight: 400;
    }
}

.block-development-services {
    width:  calc(100% - 40px);
    padding: 20px 0 54px;
    box-sizing: border-box;
    margin:  0 auto;
    display: flex;
    justify-content: center;
    // border-top: 1px solid #DBDBDB;
}

.wripper-grid-development-services {
    display: grid;
    grid-gap: 20px;
    box-sizing: border-box;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    color: #444;
}

.item-grid-development-services {
    border: 1px solid #DBDBDB;
    border-radius: 16px;
    width: 100%;
    box-sizing: border-box;
    padding:  20px 0;
    position: relative;
    &.item-1 {
        grid-column: 1;
        grid-row: 1 / span 2;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
            .title-block-development-services {
                text-align: center;
            }
            .tags-services {
                max-width: 300px;
            }
    }
    &.item-2 {
        grid-column: 2;
        grid-row:  1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #F7F7F7;
        .title-tags-development-services {
            padding-right: 20px;
        }
    }
    &.item-3 {
        grid-column: 3;
        grid-row: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .title-tags-development-services {
            padding-right: 20px;
        }
    }
    &.item-4 {
        grid-column: 2 / span 2;
        grid-row: 2;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #F7F7F7;
        .title-tags-development-services {
            padding-right: 20px;
        }
    }
}
 .point-block-development-services {
    width: 20px;
    height: 20px;
    border: 1px solid #DBDBDB;
    border-radius: 50%;
    position: absolute;
    right: 10px;
    top: 10px;
}

.lottie-development-services-dev {
    width: 85%;
}
.lottie-development-services-web {
   width: 99%;
}
.lottie-development-services-phone {
    width: 99%;
}
.lottie-development-services-blockchain {
    width: 23%;
}


.title-block-development-services {
    font-family: $font-family-1;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    margin: 10px 0;
}

.subtitle-content-services {
    width: 550px;
    font-family: $font-family-1;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 42px;
    line-height: 58px;
    text-transform: capitalize;
    &.padding {
        padding-bottom: 72px;
    }

    &.todor {
        position: relative;


        &:hover {
        cursor: pointer;
        .icon-subtitle-content-services {
            background-color: #000000;
            svg {

                rect {
                    fill: #000000;
                }

                path {
                    stroke: white;
        
                }

            }
        }
        }        
    }
}

.icon-subtitle-content-services {
    position: absolute;

    right: -40px;
    bottom: 7px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #BDBCBC;
    svg {
        width: 32px;
        height: 32px;
        path {
            stroke: black;

        }
    }

}

.container-block-clients-services {
    width: calc(100% - 40px);
    max-width: 1675px;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    border-top: 1px solid #DBDBDB;
    box-sizing: border-box;

}

 .block-clients-services{
    padding: 60px 0 54px;
    width: 100%;
    // max-width: 1440px;
    max-width: 1164px;
    box-sizing: border-box;
 }
 .list-clients {
    display: flex;
    width: 100%;
    border-radius: 32px;
    flex-wrap: wrap;
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid #DBDBDB ;
     
 }
 .item-client {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    img{
        width: 100%;
    }

    &.client-1 {
        border-right: 1px solid #DBDBDB;
        border-bottom: 1px solid #DBDBDB;
    }
    &.client-2 {
        border-right: 1px solid #DBDBDB;
        border-bottom: 1px solid #DBDBDB;
    }
    &.client-3 {
        border-right: 1px solid #DBDBDB;
        border-bottom: 1px solid #DBDBDB;
    }
    &.client-4 {
        border-bottom: 1px solid #DBDBDB;
    }
    &.client-5 {
        border-right: 1px solid #DBDBDB;
    }
    &.client-6 {
        border-right: 1px solid #DBDBDB;
    }
    &.client-7 {
        border-right: 1px solid #DBDBDB;
    }
    &.client-8 {
        border-right: none;
    }
 }

 .container-block-todorov-services{
    width: calc(100% - 40px);
    display: flex;
    margin: 0 auto;
    justify-content: center;
    border-top: 1px solid #DBDBDB;
    box-sizing: border-box;
 }

.block-todorov-services{ 
    display: flex;
    flex-direction: column;
    padding: 60px 0 0;
    display: flex;
    width: 100%;
    max-width: 1164px;
    box-sizing: border-box;
}
.the-best-block-todorov-services {
flex-direction: column;
 &.max-size {
    display: flex;
 }
 &.min-size {
    display: none;
 }
}

.body-block-todorov-services {
    &.min-size {
        display: none;
    }
    &.max-size {
        display: flex;
    }

}




.left-todorov-services {
    width: 50%;
}

.tags-services {
    font-family: $font-family-1;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #777777;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    
    span{
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        border-radius: 32px;
        padding: 6px 16px;
        margin: 6px 4px 0 0;
        img {
            margin-right: 2px;
        }
        &.tags-grey {
            background: #EEEDED; 
        }
    }
    &.left {
        justify-content: flex-start;
    }
}

.tags-todorov-services {
    margin-top: 25px;
    font-family: $font-family-1;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #777777;


    span{
        background: #EEEDED;
        border-radius: 32px;
        padding: 2px 10px;
        margin: 0 2px;
    }
}

.block-info-todorov {
    margin-top: 165px; 
    margin-bottom: 140px;
}

.title-block-info-todorov {
    display: flex;
    align-items: center;
    font-family: $font-family-1;
    span {
        padding-left: 25px;
        font-style: normal;
        font-weight: 300;
        font-size: 26px;
        line-height: 31px;
    }
}

.discription-block-info-todorov {
    font-family: $font-family-1;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #777777;
    padding-top: 25px;
    width: 70% ;
    max-width: 410px;
}

.btns-block-info-todorov {
    margin-top: 30px;
    display: flex;
}

.item-btn-block-info-todorov {
    font-family: $font-family-1;
    font-style: normal;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    border-radius: 12px;
    color: white;
    padding: 8px 10px 8px 20px;
    margin-right: 10px;
    cursor: pointer;
    user-select: none;

    .svg-icon{
        margin-left: 20px;
    }

    &.clutch {
        border: 1px solid #FF3D2E;
        .svg-icon {
             rect {
                fill: #FFE2E0;
             }
             path {
                stroke: #FF3D2E;
            }
        }
        &:hover {
            background: #FFE2E0;
            .svg-icon {
                rect {
                    fill: #FF3D2E;
                }
                path {
                    stroke: white;
                }
            }
    
           }
    }

    &.upwork {
        border: 1px solid #14A800;
        .svg-icon {
            rect {
                fill: #DCF2D9;
            }
            path {
                stroke: #14A800;
            }
       }

       &:hover {
        background: #DCF2D9;
        .svg-icon {
            rect {
                fill: #14A800;
            }
            path {
                stroke: white;
            }
        }

       }
        
    }

 
}


.right-todorov-services {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.content-right-todorov-services {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    a {
        position: absolute;
        font-family: $font-family-1;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 90px;
        text-transform: uppercase;
        color: white;
        display: flex;
        align-items: center;
        transform: rotateZ(90deg);
        height: 10px;
        right: 3.4%;
        bottom: 18%;
        transition: .4s;
        cursor: pointer;
        user-select: none;
        span {
            padding-right: 15px;
        }
        svg {
            transform: rotateZ(-90deg);
        }
        &:hover {
            opacity: 0.6;
        }
    }

    img {
        width: 100%;
    }
}
.img-todorov {
    max-width: 370px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) ;
    bottom: 0;
}



@media screen  and (max-width: 1180px){ 

    .header-content-services {
        padding: 74px 0 32px;
    }
    .item-grid-development-services { 
        &.item-1 {
            grid-column: 1  ;
            grid-row: 1 / span 2;
        }
        &.item-2 {
            grid-column: 2 / span 2;
            grid-row: 1;
        }
        &.item-3 {
            grid-column: 2 / span 2;
            grid-row: 2 ;
        }
        &.item-4 {
            grid-column: 1 / span 3;
            grid-row: 3;
        }
    }
    .lottie-development-services-dev {
        width: 61%;
    }
    .lottie-development-services-web {
       width: 40%;
    }
    .lottie-development-services-phone {
        width: 35%;
    }
    .lottie-development-services-blockchain {
        width: 20%;
    }


    .block-clients-services {
        padding: 42px 0 ;
    }
    .subtitle-content-services.padding {
        padding-bottom: 54px;
    }


    .block-todorov-services {
        padding-top: 42px
    }


    .the-best-block-todorov-services {
        &.max-size {
            display: none;
        }
        &.min-size {
            display: flex;
        }
        padding-bottom: 32px;
    }


    .block-info-todorov {
        margin:  0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    .img-todorov {
        width: 33%;
    }
    .title-block-info-todorov {
        svg {
            height: 58px;
            width: 58px;
        }
        span {
            padding-left: 20px;
        }
    }
    .discription-block-info-todorov {
        max-width: 300px;
    }
    .item-btn-block-info-todorov { 
        padding: 8px ;
    }

} 


@media screen  and (max-width: 900px){  

    .block-info-todorov {
        margin: 0;
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        box-sizing: border-box;
    }

    .discription-block-info-todorov {
        padding-top: 10px ;
    }

    .btns-block-info-todorov {
        margin-top: 15px;
    }

}


@media (max-width: 767px) {  

    .header-content-services {
        padding: 54px 0 32px;
        margin: 0 6px;
        width: calc(100% - 12px);
    }
    .title-header-services {
        font-size: 46px;
        line-height: 56px;
    }


    .block-development-services { 
        padding: 6px 0 42px;
        width:  calc(100% - 12px);
    }
    .wripper-grid-development-services { 
        grid-gap: 6px;
        grid-template-columns: 1fr;
    }
    .item-grid-development-services { 
        &.item-1 {
            grid-column: 1;
            grid-row: 1;
            flex-direction: row;
            justify-content: flex-start;
            .title-block-development-services {
                text-align: start;
            }
            .tags-services {
               max-width: none;
               justify-content: flex-start;
            }
            .title-tags-development-services {
                padding-right: 20px;
            }
        }
        &.item-2 {
            grid-column: 1;
            grid-row: 2;
        }
        &.item-3 {
            grid-column: 1;
            grid-row: 3;
        }
        &.item-4 {
            grid-column: 1;
            grid-row: 4;
        }
    }
    .lottie-development-services-dev {
        width: 58%;
    }
    .lottie-development-services-web {
       width: 70%;
    }
    .lottie-development-services-phone {
        width:55%;
    }
    .lottie-development-services-blockchain {
        width: 87%;
    }
    .title-block-development-services {
        font-size: 21px;
        line-height: 25px;
    }
    .tags-services {
        span {
            font-size: 12px;
            line-height: 15px;
        }
    }
    .point-block-development-services {
        width: 14px;
        height: 14px;
        left: 10px;
    }




    .container-block-clients-services {
        width: calc(100% - 12px);
    }
    .block-clients-services {
        padding: 20px 0  42px;
    }
    .subtitle-content-services.padding {
        padding-bottom: 34px;
        font-size: 24px;
        line-height: 36px;
    }
    .icon-subtitle-content-services {
        bottom: 1;
        right: -30px;
    }
    .item-client {
        width: 50%;

        &.client-2 {
            border-right: none;
        }
        &.client-3 {
            border-right: 1px solid #DBDBDB;
            border-bottom: 1px solid #DBDBDB;
        }
        &.client-4 {
            border-bottom: 1px solid #DBDBDB;
        }
        &.client-5 {
            border-right: 1px solid #DBDBDB;
            border-bottom: 1px solid #DBDBDB;
        }
        &.client-6 {
            border-right: none;
            border-bottom: 1px solid #DBDBDB;
        }
        &.client-7 {
            border-right: 1px solid #DBDBDB;
        }
        &.client-8 {
            border-right: none;
        }
    }


    .container-block-todorov-services {
        width: calc(100% - 12px);
    }
    .block-todorov-services {
        padding-top: 20px
    }
    .subtitle-content-services {
        font-size: 24px;
        line-height: 36px;
        max-width: 330px;
    }

    .icon-subtitle-content-services {
        bottom: 1px;
        right: -30px;
    }


    .body-block-todorov-services {
        &.min-size {
            display: flex;
            width: 100%;
            box-sizing: border-box;
            flex-direction: column;
        }
        &.max-size {
            display: none;
        }
    }

    .container-todorov-mini-bg {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .bg-services-todorov {
        width: 100%;
        border-radius: 16px;
    }

    .img-todorov-mini {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        bottom: 4px;
        width: 55%;
    }

    .btns-block-info-todorov {
        margin: 10px 0 84px;
        display: flex;
        justify-content: space-between;


    }
    .item-btn-block-info-todorov {
        width: calc(50% - 5px);
        margin: 0;
        padding: 8px 10px 8px 20px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        &:hover {
            svg{
                .svg-icon {
                    rect {
                        fill: rgba(255, 255, 255, 0.15);
                    }
                    path{
                        stroke: white;
                    }
                }
            }
        }
    }
}


@media screen  and (min-width: 1681px){  

    .block-development-services  {
        max-width: 1250px;
    }
    .block-clients-services {
        max-width: 1250px;
    }
    .block-todorov-services {
        max-width: 1250px;
    }
}





@media (max-height: 640px)  {   



    .header-content-services {
        padding: 54px 0 32px;
        margin: 0 6px;
        width: calc(100% - 12px);
    }
    .title-header-services {
        font-size: 46px;
        line-height: 56px;
    }


    .block-development-services { 
        padding: 6px 0 42px;
        width:  calc(100% - 12px);
    }
    .wripper-grid-development-services { 
        grid-gap: 6px;
    }

    .title-block-development-services {
        font-size: 21px;
        line-height: 25px;
    }
    .tags-services {
        span {
            font-size: 12px;
            line-height: 15px;
        }
    }
    .point-block-development-services {
        width: 14px;
        height: 14px;
        left: 10px;
    }

    .container-block-clients-services {
        width: calc(100% - 12px);
    }
    .block-clients-services {
        padding: 20px 0  42px;
    }
    .subtitle-content-services.padding {
        padding-bottom: 34px;
        font-size: 24px;
        line-height: 36px;
    }

    .container-block-todorov-services {
        width: calc(100% - 12px);
    }
    .block-todorov-services {
        padding-top: 20px
    }
    .subtitle-content-services {
        font-size: 24px;
        line-height: 36px;
        max-width: 330px;
    }
    .icon-subtitle-content-services {
        bottom: 1px;
        right: -30px;
    }
    

}



