@import "../../style/fonts.scss";
.wrapper-contact-t3d {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  border: 20px solid white;
  background-color: white;
  box-sizing: border-box;
  position: relative;
  // transform-origin: 50% 100%;
  // transform: translateY(0%);
  // opacity: 1;
  // animation-name: showpageContact;
  // animation-duration: 1s;
  // &.hidden {
  //     animation-name: hiddenpage;
  //     animation-duration: 0.5s;
  //     opacity: 1;
  // }
}

.wrapper-waiting {
  background-color: rgba(137, 137, 137, 0.572);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1111;
}

.spinner {
  position: absolute;
  width: 30px;
  height: 30px;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .blob {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #fff;

    width: 10px;
    height: 10px;
    border-radius: 50%;

    &.top {
      top: 0;
      animation: blob-top 1s infinite ease-in;
    }
    &.bottom {
      top: 100%;
      animation: blob-bottom 1s infinite ease-in;
    }
    &.left {
      left: 0;
      animation: blob-left 1s infinite ease-in;
    }
  }

  .move-blob {
    background: #fff;
    top: 0;

    animation: blob-spinner-mover 1s infinite ease-in;
  }
}

@keyframes blob-bottom {
  25%,
  50%,
  75% {
    top: 50%;
    left: 100%;
  }
  100% {
    top: 0;
    left: 50%;
  }
}
@keyframes blob-left {
  25% {
    top: 50%;
    left: 0;
  }
  50%,
  100% {
    top: 100%;
    left: 50%;
  }
}
@keyframes blob-top {
  50% {
    top: 0;
    left: 50%;
  }
  75%,
  100% {
    top: 50%;
    left: 0;
  }
}

@keyframes blob-spinner-mover {
  0%,
  100% {
    top: 0;
    left: 50%;
  }
  25% {
    top: 50%;
    left: 100%;
  }
  50% {
    top: 100%;
    left: 50%;
  }
  75% {
    top: 50%;
    left: 0;
  }
}

.light-cube {
  position: absolute;
  width: 25%;
  height: 100%;
  left: 0;
  top: -40%;
  background: #52b6ff;
  opacity: 0.25;
  filter: blur(100px);
  transform: rotate(66.69deg);
  z-index: 10;
  pointer-events: none;
}
.light-circle {
  position: absolute;
  width: 25vw;
  height: 25vw;
  right: -12vw;
  top: -12vw;
  background: #73dde8;
  opacity: 0.2;
  filter: blur(100px);
  z-index: 10;
  pointer-events: none;
}
.container-content-contact {
  width: 100%;
  height: 100%;
  border-radius: 24px;
  background: linear-gradient(
    323.95deg,
    #114086 0%,
    #0c3774 26.35%,
    #003167 53.73%,
    #022e5f 80.65%,
    #002862 100%
  );
  position: absolute;
  left: 0;
  top: 0;
  // padding: 20px 40px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.block-contact-center {
  width: 100%;
  max-width: 1164px;
  display: flex;
  flex-direction: column;
}
.body-contact {
  display: flex;
  border-bottom: 1px solid white;
  padding-bottom: 60px;
}
.left-block-contant {
  width: 330px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.left-block-content-contant {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.request-a-call-contact {
  width: 100%;
  max-width: 330px;
  background: linear-gradient(180deg, #101834 0%, #1a2653 100%);
  border-radius: 24px;
  padding: 55px 25px 15px 25px;
  position: relative;
  box-sizing: border-box;
}
.block-text-request-a-call-contact {
  padding-top: 50px;
}
.line-request-a-call-contact {
  width: 70px;
  height: 1px;
  border-top: 1px solid #78b6ff;
  margin-bottom: 20px;
}
.title-request-a-call-contact {
  font-family: $font-family-1;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 5px;
}
.discription-request-a-call-contact {
  font-family: $font-family-1;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #828ba4;
}
.btn-request-a-call-contact {
  border-radius: 12px;
  border: 1px solid #828ba4;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 7px 7px 23px;
  box-sizing: border-box;
  margin-top: 85px;
  cursor: pointer;
  user-select: none;
  span {
    font-family: $font-family-1;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: white;
  }
  &:hover {
    border: 1px solid white;
    svg {
      rect {
        fill: white;
      }
      path {
        stroke: black;
      }
    }
  }
}

.title-using-services {
  display: flex;
  justify-content: center;
  font-family: $font-family-1;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 15px;

  color: #828ba4;
  img {
    margin-left: 8px;
  }
}
.container-right-block-contant {
  display: flex;
  height: 100%;
  align-items: center;
}
.right-block-contant {
  display: flex;
  padding-left: 60px;
  box-sizing: border-box;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  input {
    background-color: transparent;
    outline: none;
    border: 0;
    border-bottom: 1px solid white;
    padding: 10px 10px 20px;
    font-family: $font-family-1;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
  }
}
.title-content-contact {
  color: #ffffff;
  font-family: $font-family-1;
  font-style: normal;
  font-weight: 400;
  font-size: 124px;
  line-height: 150px;
  margin-bottom: 50px;
  span {
    font-family: $font-family-4;
    font-weight: 400;
  }
}
.form-contact {
  width: 100%;
}

.wrapper-input {
  box-sizing: border-box;
  width: 100%;
  color: #444;
  display: flex;
  justify-content: space-between;
  .item-form-input {
    border-radius: 0px;
    width: calc(50% - 20px);
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid white;
    font-family: $font-family-1;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 25px;
    padding-left: 2px;
    &.err {
      border-bottom: 1px solid red;
    }
  }
}
.wrapper-btn-send-form-contact {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.btn-send-form-contact {
  background: #ffffff;
  border-radius: 12px;
  width: 100%;
  max-width: 280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 24px;
  box-sizing: border-box;
  margin-top: 45px;
  cursor: pointer;
  user-select: none;
  span {
    font-family: $font-family-1;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }
  svg {
    fill: black;
  }
  &:hover {
    background: #d8dfe8;
    svg {
      rect {
        fill: white;
      }
    }
  }
}
.header-contact {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  font-family: $font-family-1;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 30px;
  margin-bottom: 60px;
}
.header-location-contact {
  align-items: center;
  margin-top: 15px;
  font-family: $font-family-1;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  &.max-size {
    display: flex;
  }
  svg {
    margin-right: 10px;
  }

  .item-footer {
    padding-left: 10px;
    span {
      color: #a2adce;
    }
  }
}

.containter-left-block-contant {
  width: 100%;
  width: fit-content;
  display: flex;
  margin-top: 15px;
  flex-direction: column;
}
.title-left-block-contant {
  font-family: $font-family-1;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  span {
    color: #a2adce;
  }
}

@media screen and (max-width: 1180px) and (min-height: 640px) {
  .container-content-contact {
    align-items: flex-start;
    padding: 40px 20px 20px;
  }
  .header-contact {
    margin-bottom: 44px;
  }
  .body-contact {
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
  }
  .request-a-call-contact {
    padding: 24px 16px 16px;
  }
  .request-a-call-contact-call {
    display: none;
  }
  .block-text-request-a-call-contact {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .line-request-a-call-contact {
    display: none;
  }
  .btn-request-a-call-contact {
    margin-top: 32px;
  }
  .wrapper-input {
    display: flex;
    .item-form-input {
      max-width: 420px;
    }
  }

  .wrapper-btn-send-form-contact {
    justify-content: center;
  }
  .btn-send-form-contact {
    margin-top: 60px;
  }
  .title-content-contact {
    padding: 32px 0;
    margin-bottom: 0;
  }
  .right-block-contant {
    margin-bottom: 44px;

    padding: 0;
  }

  .body-contact {
    border: none;
  }
}

@media screen and (max-height: 825px) {
  // .container-content-contact {
  //     align-items: flex-start;
  // }
}

@media screen and (max-height: 730px) {
  .container-content-contact {
    padding: 20px 20px 20px;
  }
  .header-contact {
    margin-bottom: 40px;
    padding-bottom: 15px;
  }
  .request-a-call-contact {
    padding: 24px 16px 16px;
  }
  .title-content-contact {
    margin-bottom: 20px;
  }
  .btn-send-form-contact {
    margin-top: 60px;
  }
  .body-contact {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 1180px) and (max-height: 960px) {
  .header-contact {
    margin-bottom: 40px;
    padding-bottom: 20px;
  }
  .title-content-contact {
    padding: 0;
    margin-bottom: 20px;
  }

  .btn-send-form-contact {
    margin-top: 40px;
  }
  .body-contact {
    border: none;
  }
  .container-content-contact {
    padding: 20px;
  }
}

@media screen and (max-width: 1180px) and (max-height: 770px) {
  .container-content-contact {
    padding: 20px;
  }
  .header-contact {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .title-content-contact {
    padding: 0;
    margin-bottom: 0;
  }

  .header-contact {
    margin-bottom: 20px;
    padding-bottom: 15px;
  }

  .body-contact {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 1180px) and (max-height: 720px) {
  .header-location-contact.max-size {
    margin-top: 0;
  }
  .containter-left-block-contant {
    margin-top: 0;
  }
  .btn-send-form-contact {
    margin-top: 20px;
  }
}

@media screen and (max-height: 640px) {
  .wrapper-contact-t3d {
    border: 6px solid white;
  }
  .container-content-contact {
    border-radius: 12px;
    padding: 20px;
    align-items: flex-start;
  }
  .header-contact {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .containter-left-block-contant {
    margin-top: 5px;
  }
  .wrapper-input {
    .item-form-input {
      font-size: 16px;
      line-height: 19px;
      width: 100%;
      width: calc(50% - 10px);
      max-width: 318px;
      box-sizing: border-box;
    }
  }
  .title-content-contact {
    padding: 0 0 20px 0;
    font-size: 46px;
    line-height: 56px;
    width: 100%;
    margin-bottom: 20px;
  }
  .btn-send-form-contact {
    margin-top: 60px;
  }
  .right-block-contant {
    margin-bottom: 20px;
    padding-left: 0;
  }
  .request-a-call-contact-call {
    display: none;
  }
  .body-contact {
    justify-content: space-evenly;
    padding-bottom: 0;
    border-bottom: none;
  }
  .header-contact {
    margin-bottom: 20px;
  }
  .block-text-request-a-call-contact {
    padding-top: 25px;
  }
  .btn-request-a-call-contact {
    margin-top: 80px;
  }
}

@media screen and (max-height: 470px) {
  .containter-left-block-contant {
    margin-top: 0;
  }
  .header-location-contact {
    &.max-size {
      margin-top: 0;
    }
  }
  .header-contact {
    padding-bottom: 10px;
  }
  .title-content-contact {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .btn-send-form-contact {
    margin-top: 25px;
  }
  .btn-request-a-call-contact {
    margin-top: 20px;
  }
  .block-text-request-a-call-contact {
    padding-top: 20px;
  }
  .request-a-call-contact {
    padding-top: 16px;
  }

  .left-block-contant {
    width: 300px;
  }
}

@media screen and (max-width: 767px) and (min-height: 620px) {
  .wrapper-contact-t3d {
    border: 6px solid white;
  }
  .container-content-contact {
    border-radius: 12px;
    padding: 20px;
    align-items: center;
  }
  .header-contact {
    justify-content: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .request-a-call-contact {
    padding: 24px 16px 16px;
  }
  .request-a-call-contact-call {
    display: none;
  }
  .block-text-request-a-call-contact {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .line-request-a-call-contact {
    display: none;
  }
  .wrapper-input {
    display: flex;
    justify-content: center;
  }
  .wrapper-btn-send-form-contact {
    justify-content: center;
  }
  .header-location-contact {
    margin-top: 25px;
    &.max-size {
      display: none;
    }
  }
  .containter-left-block-contant {
    margin-top: 5px;
  }
  .wrapper-input {
    align-items: center;
    .item-form-input {
      font-size: 16px;
      line-height: 19px;
      width: 100%;
      max-width: 318px;
      margin-bottom: 10px;
    }
  }
  .title-content-contact {
    padding: 20px 0;
    font-size: 46px;
    line-height: 56px;
    width: 100%;
    text-align: center;
    margin-bottom: -15px;
  }
  .container-right-block-contant {
    width: 100%;
  }
  .btn-send-form-contact {
    margin-top: 20px;
  }
  .right-block-contant {
    margin-bottom: 20px;
  }
  .body-contact {
    border: none;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
  }
  .right-block-contant {
    padding-left: 0;
  }
  .wrapper-input {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .wrapper-btn-send-form-contact {
    justify-content: center;
  }
}

@media screen and (max-width: 767px) and (orientation: portrait) {
  .btn-request-a-call-contact {
    margin-top: 32px;
  }

  .container-right-block-contant {
    padding-left: 0;
  }
}

@media screen and (max-width: 400px) {
  .left-block-contant {
    width: 100%;
  }

  .request-a-call-contact {
    max-width: none;
  }

  .wrapper-input {
    .item-form-input {
      max-width: none;
    }
  }

  .item-form-input {
    width: calc(50% - 10px);
    min-width: none;
  }

  .btn-send-form-contact {
    width: 100%;
    max-width: none;
  }
}

@media screen and (max-width: 767px) and (max-height: 700px) {
  .container-content-contact {
    align-items: flex-start;
  }
}

@media screen and (max-width: 900px) {
  .header-location-contact {
    &.max-size {
      display: none;
    }
  }
}
