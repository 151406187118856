@import "../../style/fonts.scss";

.wrapper-home-t3d {
  width: 100%;
  height: 100%;
  // transform: translateY(0);
  // opacity: 1;
  // animation-name: showpageHome;
  // animation-duration: 1s;
  // transform-origin: 50% 100%;
  // &.hidden {
  //     animation-name: hiddenpage;
  //     animation-duration: 0.5s;
  //     transform: scale(0.97);
  // }
}

.container-home-t3d {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 20px solid white;
  box-sizing: border-box;
  background-color: white;
  video {
    width: 100%;
    object-fit: cover;
    height: 100%;
    box-sizing: border-box;
    border-radius: 24px;
    background: linear-gradient(
      323.95deg,
      #114086 0%,
      #0c3774 26.35%,
      #003167 53.73%,
      #022e5f 80.65%,
      #002862 100%
    );
  }
}

.container-content-home {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px 42px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo-home-t3d {
  position: relative;
  width: 80px;
  position: absolute;
  left: 42px;
  top: 64px;
}

.title-home-t3d {
  display: flex;
  flex-direction: column;
  font-family: $font-family-1;
  font-style: normal;
  font-weight: 300;
  font-size: 56px;
  line-height: 68px;
  text-transform: uppercase;
  color: #ffffff;
  width: 820px;
  &.br {
    display: none;
  }
  span {
    color: white;
    font-weight: 700;
    font-size: 105px;
    line-height: 127px;
    // background: linear-gradient(180deg, #7BA0FF 20%, #82A5FF 40.25%, #FFE8AD 65.57%);

    background: linear-gradient(
      135deg,
      #7ba0ff,
      #7ba0ff,
      #ffe8ad,
      #82a5ff,
      #82a5ff,
      #ffe8ad
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    background-size: 250% 250%;
    animation: gradient 10s ease infinite;
  }
}

@keyframes gradient {
  0% {
    background-position: 80% 0%;
  }
  50% {
    background-position: 20% 100%;
  }
  100% {
    background-position: 80% 0%;
  }
}

.info-company-home-t3d {
  display: flex;
  margin-top: 25px;
}

.line-note-home-t3d {
  width: 70px;
  height: 1px;
  background-color: white;
  margin-bottom: 5px;
}

.note-home-t3d {
  position: absolute;
  bottom: 34px;
  left: 42px;
}

.text-note-home-t3d {
  font-family: $font-family-3;
  font-style: normal;
  font-weight: 300;
  color: #ffffff;
  font-size: 16px;
  line-height: 19px;
  padding-top: 12px;
  span {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    text-transform: uppercase;
    padding-right: 10px;

    &:last-child {
      padding-right: 0px;
    }
  }
}

@media (max-width: 1180px), (max-height: 730px) {
  .logo-home-t3d {
    width: 100px;
    left: 40px;
    top: 40px;
  }
  // .title-home-t3d {
  //     font-size: 54px;
  //     line-height: 65px;
  //     span {
  //         font-size: 108px;
  //         line-height: 131px;
  //     }
  // }
  .line-note-home-t3d {
    display: none;
  }
  .note-home-t3d {
    right: 40px;
    top: calc(40px - 12px);
  }
  .text-note-home-t3d {
    text-align: end;
    span {
      padding-right: 6px;
    }
  }
  .container-content-home {
    align-items: center;
    justify-content: flex-end;
  }
  .center-block-home-t3d {
    text-align: center;
    position: absolute;
    bottom: 70%;
    transform: translateY(70%);
  }
  // .title-home-t3d {
  //     padding-top: 30%;
  // }
  .info-company-home-t3d {
    justify-content: center;
    display: none;
  }
}

@media (max-width: 767px), (max-height: 640px) {
  .container-home-t3d {
    border: 6px solid white;
    video {
      border-radius: 12px;
    }
  }

  .logo-home-t3d {
    width: 70px;
    left: 15px;
    top: 25px;
  }
  .title-home-t3d {
    width: 320px;
    font-size: 29px;
    line-height: 31px;
    display: none;
    span {
      font-size: 54px;
      line-height: 65px;
    }
    &.br {
      display: flex;
    }
  }
  .line-note-home-t3d {
    display: none;
  }
  .note-home-t3d {
    right: 14px;
    top: calc(35px - 6px);
  }
  .text-note-home-t3d {
    text-align: end;
    padding-top: 6px;
    font-size: 12px;
    line-height: 14px;

    span {
      font-size: 14px;
      line-height: 18px;
    }
  }

  // .title-home-t3d {
  //     padding-top: 70%;
  // }
}

@media (max-height: 400px) {
  .note-home-t3d {
    top: 15px;
  }
}

@media screen and (max-width: 880px) {
  .title-home-t3d {
    display: none;
    &.br {
      display: flex;
    }
  }
}
