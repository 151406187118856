@import "../../style/fonts.scss";
.wrapper-item-info-home {
    width: 110px;
    background: rgba(0, 0, 0, 0.46);
    backdrop-filter: blur(30px);
    border-radius: 16px;
    padding: 15px 10px;
    box-sizing: border-box;
    margin-right: 8px;
}

.qty-item-info-home {
    font-family: $font-family-3;
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 12px rgba(255, 255, 255, 0.65);
    padding-bottom: 12px;
}

.title-item-info-home {
    font-family: $font-family-2;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #A2ADCE;
}


@media screen  and (max-width: 1180px){ 
    .wrapper-item-info-home {
        margin: 0 4px;
    }
} 