@import "../../style/fonts.scss";

    .wrapper-our-portfolio-t3d{
        width: 100%;
        transform-origin: 50% 100%;
        background-color: white;

        transform: translateY(0);
        opacity: 1;
        animation-name: showpageOurPortfolio;
        animation-duration: 1s;
    
        &.hidden {
            animation-name: hiddenpage;
            animation-duration: 0.5s;
            opacity: 1;
        }
    }

    .wrapper-btn-back-works {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .container-btn-back-works {
        width: 400px;
        height: 124px;
        border-left: 1px solid #DBDBDB;
        border-right: 1px solid #DBDBDB;
        border-bottom: 1px solid #DBDBDB;
        border-radius: 0px 0px 333px 333px;
        position: relative;
    }
    .btn-back-works {
        text-decoration: none;
        color: #000000;
        position: absolute;
        background-color: white;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        padding: 12px 10px;
        border: 1px solid #DBDBDB;
        border-radius: 12px;
        font-family: $font-family-1;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        span {
            padding: 0 20px;
        }

        svg{
            margin-right: 10px;
            transform: rotate(-180deg);
        }
        cursor: pointer;
        user-select: none;
        &:hover {
            border: 1px solid black;
            svg {
                rect {
                    fill: black;
                }
                path {
                    fill: white;
                }
            }
        }
    }

    .container-content-our-portfolio {
        padding: 54px 20px 20px;
        box-sizing: border-box;
        width: 100%;

    }

    .header-content-our-portfolio {
        display: flex;
        width: 100%;
        justify-content:  center;
        align-items: flex-end;
        text-align: center;
        padding-bottom:32px;
    }
    .title-header-our-portfolio {
        font-family: $font-family-1;
        font-style: normal;
        font-weight: 400;
        font-size: 124px;
        line-height: 150px;
        color: #000000;
        span {
            font-family: $font-family-4;
            font-weight: 400;
        }
    }

.container-projects {
    width: 100%;
    position: relative;
    // padding-bottom: 100px;
}

.wrapper-clear-filter{
    display: flex;
    align-items: center;
    margin-left: 5px;
    &.disable {
        opacity: 0;
        pointer-events: none;
    }
}
.partition-filter {
    border-left: 1px;
    height: 28px;
    width: 1px;
    background-color:   #DBDBDB;
}
.clear-filter {
    border: 1px solid transparent;
    font-family: $font-family-1;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    cursor: pointer;
    user-select: none;
    margin: 0 7px;
    display: flex;
    align-items: center;
    border: 1px solid #DBDBDB;
    background: #EEEEEE;
    border-radius: 12px;

    div {
        padding: 12px;
    }
    svg {
        margin-right: 6px;
    }
    &:hover {
        border: 1px solid black;
        background: white;
        svg {
            rect {fill: black;}
            path {fill: white;}
        }
    }


}


.filter-container-content-fixed {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    min-height: 80px;
    padding: 0 20px;
    box-sizing: border-box;
    position: fixed;
    top: 20px;
    left: 0;
    right: 0;
    z-index: 1001;

}

.filter-content-fixed {
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.46);
    backdrop-filter: blur(30px);
    border-radius: 15px;
    width: calc(100% - 80px - 20px);
    margin-right: 20px;
    box-sizing: border-box;
    color: white;
    padding: 0 40px;
    
}


.body-filter-content-fixed {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.toggle-filter-content-fixed {
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    background: rgba(0, 0, 0, 0.46);
    backdrop-filter: blur(30px);

    color: white;
    font-family: $font-family-1;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    user-select: none;
    flex-direction: column;
    transition: 0.4s;
    border-radius: 15px;

    &:hover {

        border-radius: 32px;
    }

    &.mobile {
        border-radius: 15px 0px 0px 15px;
        display: none;
    }

    &.desctop {
        display: flex;
    }

}


@media screen  and (max-width: 1180px) {   
    .body-filter-content-fixed { 
        padding: 5px 0;
    }

    .container-btn-back-works {
        height: 124px;
        width: 340px;
    }


}




@media (max-width: 767px), (max-height: 640px){  
    .container-btn-back-works {
        width: 278px;
        height: 86px;
    }
    .btn-back-works {
        font-size: 14px;
        line-height: 18px;
        width: 136px;
        height: 28px;
        svg {
            width: 30px;
            height: 30px;
        }
    }
    .container-content-our-portfolio {
        padding: 45px 6px 6px;
    }
    .header-content-our-portfolio {
        padding-bottom: 24px;
    }
    .title-header-our-portfolio {
        font-size: 46px;
        line-height: 56px;
    }
    .body-filter-content-fixed { 
        max-width: 360px;
        justify-content: center;
    }
    .filter-content-fixed {
        width: 100%;
        margin: 0;
        border-radius: 0 0 15px 15px;
        justify-content: center;
    }
    .filter-container-content-fixed {
        padding: 0;
        top: 0;
    }
    .toggle-filter-content-fixed  {
        height: 70px;
        width: 70px;
        &.mobile {
            display: flex;
            position: fixed;
            bottom: 25%;
            z-index: 1001;
            right: 0;
        }
        &.desctop {
            display: none;
        }
    }
}


